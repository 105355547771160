<template>
  <div class="appbg">
    <div>
      <div>
        <span>
          <span style="color: red;">*</span>审批名称
          <span style="color: #cccccc;padding-left: 30px;">最多20字</span>
        </span>
        <div style="padding-top: 20px;">
          <el-input v-model="approvalName" placeholder="请输入审批名称" size="medium" maxlength="20"> </el-input>
        </div>
      </div>
      <div style="padding-top: 20px;">
        <span>
          谁可以管理这个模板并导出数据
          <span style="color: #cccccc;padding-left: 30px;">只有模版管理员可以编辑这个审批单和导出数据</span>
        </span>
        <div style="padding-top: 20px;">
          <el-button class="btnSpan"
            style="width: 675px;height: 40px;border: 1px solid #DCDFE6;color: #DCDFE6;text-align: left;text-indent: 1em;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;"
            type="text" @click="dialogVisible = true" v-model="approvalManagement" size="medium" maxlength="50">
            <span :style="{'color': checkop.length ? '#000' : '#dcdfe6','display': 'inline' }" v-show="checkop.length"
              v-for="(i,index) in checkop" :key="index">{{i+','}}</span>
            <span v-if="!checkop.length">请输入管理员</span>
          </el-button>
        </div>
      </div>

      <!-- 管理模板导出弹框 -->
      <c-tree :dialogVisible.sync="dialogVisible" @changeDialog="changeDialog" :drtjData="drtjData"></c-tree>

      <div style="padding-top: 20px;">
        <span>
          审批说明
          <span style="color: #cccccc;padding-left: 30px;">最多50字</span>
        </span>
        <div style="padding-top: 20px;">
          <el-input v-model="approvalInstructions" placeholder="请输入审批说明" size="medium" maxlength="50"></el-input>
        </div>
      </div>
      <div style="padding-top: 20px;">
        <span>
          <span style="color: red;">*</span>选择分组
        </span>
        <div style="padding-top: 20px;">
          <el-select v-model="grouping" placeholder="请选择分组" size="medium" maxlength="50">
            <el-option v-for="(item, index) in options" :label="item.flow_group_name" :value="item.flow_group_id"
              :key="index"></el-option>
          </el-select>
        </div>
      </div>
      <div class="iconbox" v-if="tubiaoType == 'xinjian' || formTypeBtn == '1'">
        <p class="tit">
          图标设置
        </p>
        <div class="fieldblock">
          <div class="wf-iconselect">
            <img v-for="(item,index) in icons" :key="index" @click="iconFun(index)"
              :style="{'border': item.value == iconUrl ? '1px solid #3f9af9' : '1px solid #ccc'}" :src="item.value">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import {
    get_my_info,
    query_form_replace_write,
    query_flow_group_info_list
  } from '@api/public.js';
  import {
    query_dept_staff_tree_aim_not,
    insert_cust_form
  } from '@api/user.js';
  import {
    query_cust_form_detail,
    query_flow_info_all,
    query_manager_of_form
  } from '@api/shenpi.js';
  export default {
    data() {
      return {
        // 图标设置
        iconUrl: 'https://www.mlcbr.com/images/logo/tb1.png', // 当前url地址
        icons: [{
            value: 'https://www.mlcbr.com/images/logo/tb1.png'
          },
          {
            value: 'https://www.mlcbr.com/images/logo/tb2.png'
          },
          {
            value: 'https://www.mlcbr.com/images/logo/tb3.png'
          },
          {
            value: 'https://www.mlcbr.com/images/logo/tb4.png'
          },
          {
            value: 'https://www.mlcbr.com/images/logo/tb5.png'
          },
          {
            value: 'https://www.mlcbr.com/images/logo/tb6.png'
          },
          {
            value: 'https://www.mlcbr.com/images/logo/tb7.png'
          },
          {
            value: 'https://www.mlcbr.com/images/logo/tb8.png'
          },
          {
            value: 'https://www.mlcbr.com/images/logo/tb9.png'
          },
          {
            value: 'https://www.mlcbr.com/images/logo/tb10.png'
          },
          {
            value: 'https://www.mlcbr.com/images/logo/tb11.png'
          },
          {
            value: 'https://www.mlcbr.com/images/logo/tb12.png'
          },
          {
            value: 'https://www.mlcbr.com/images/logo/tb13.png'
          },
          {
            value: 'https://www.mlcbr.com/images/logo/tb14.png'
          },
          {
            value: 'https://www.mlcbr.com/images/logo/tb15.png'
          },
          {
            value: 'https://www.mlcbr.com/images/logo/tb16.png'
          },
          {
            value: 'https://www.mlcbr.com/images/logo/tb17.png'
          },
          {
            value: 'https://www.mlcbr.com/images/logo/tb18.png'
          },
          {
            value: 'https://www.mlcbr.com/images/logo/tb19.png'
          },
          {
            value: 'https://www.mlcbr.com/images/logo/tb20.png'
          },
          {
            value: 'https://www.mlcbr.com/images/logo/tb21.png'
          },
          {
            value: 'https://www.mlcbr.com/images/logo/tb22.png'
          },
          {
            value: 'https://www.mlcbr.com/images/logo/tb23.png'
          },
          {
            value: 'https://www.mlcbr.com/images/logo/tb24.png'
          },
          {
            value: 'https://www.mlcbr.com/images/logo/tb25.png'
          },
          {
            value: 'https://www.mlcbr.com/images/logo/tb26.png'
          },
          {
            value: 'https://www.mlcbr.com/images/logo/tb27.png'
          },
          {
            value: 'https://www.mlcbr.com/images/logo/tb28.png'
          },
          {
            value: 'https://www.mlcbr.com/images/logo/tb29.png'
          },
          {
            value: 'https://www.mlcbr.com/images/logo/tb30.png'
          },
          {
            value: 'https://www.mlcbr.com/images/logo/tb31.png'
          },
          {
            value: 'https://www.mlcbr.com/images/logo/tb32.png'
          },
          {
            value: 'https://www.mlcbr.com/images/logo/tb33.png'
          },
          {
            value: 'https://www.mlcbr.com/images/logo/tb34.png'
          },
          {
            value: 'https://www.mlcbr.com/images/logo/tb35.png'
          },
          {
            value: 'https://www.mlcbr.com/images/logo/tb36.png'
          },
          {
            value: 'https://www.mlcbr.com/images/logo/tb37.png'
          },
          {
            value: 'https://www.mlcbr.com/images/logo/tb38.png'
          },
          {
            value: 'https://www.mlcbr.com/images/logo/tb39.png'
          },
          {
            value: 'https://www.mlcbr.com/images/logo/tb40.png'
          },
          {
            value: 'https://www.mlcbr.com/images/logo/tb41.png'
          },
          {
            value: 'https://www.mlcbr.com/images/logo/tb42.png'
          },
          {
            value: 'https://www.mlcbr.com/images/logo/tb43.png'
          },
          {
            value: 'https://www.mlcbr.com/images/logo/tb44.png'
          },
          {
            value: 'https://www.mlcbr.com/images/logo/tb45.png'
          },
          {
            value: 'https://www.mlcbr.com/images/logo/tb46.png'
          },
          {
            value: 'https://www.mlcbr.com/images/logo/tb47.png'
          },
          {
            value: 'https://www.mlcbr.com/images/logo/tb48.png'
          },
          {
            value: 'https://www.mlcbr.com/images/logo/tb49.png'
          },
          {
            value: 'https://www.mlcbr.com/images/logo/tb50.png'
          },
          {
            value: 'https://www.mlcbr.com/images/logo/tb51.png'
          },
          {
            value: 'https://www.mlcbr.com/images/logo/tb52.png'
          }
        ],
        tubiaoType: '',
        deitData: '',
        // 选择人员弹出框
        dialogVisible: false,
        activeNames: '',
        drtjData: [],
        checkop: [],
        checkopId: [],
        dialogVisible: false,
        formTypeBtn: '',
        approvalName: '',
        grouping: '',
        approvalInstructions: '',
        approvalManagement: '',
        options: []
      }
    },
    watch: {
      approvalName(newval, oldval) {
        if (newval) {
          var newData = JSON.parse(sessionStorage.getItem('xinjianType')) || {
            flow_name: ''
          };
          newData.flow_name = newval;
          sessionStorage.setItem('xinjianType', JSON.stringify(newData));
          console.log(sessionStorage.getItem('xinjianType'))
        }

      },
      grouping(newval, oldval) {
        if (newval) {
          var newData = JSON.parse(sessionStorage.getItem('xinjianType'));
          newData.flow_group_id = newval;
          sessionStorage.setItem('xinjianType', JSON.stringify(newData));
          console.log(sessionStorage.getItem('xinjianType'), this.options)
        }
      },
      iconUrl(newval, oldval) {
        if (newval) {
          var newData = JSON.parse(sessionStorage.getItem('xinjianType'));
          newData.logo_url = newval;
          sessionStorage.setItem('xinjianType', JSON.stringify(newData));
          console.log(sessionStorage.getItem('xinjianType'))
        }
      },
      checkop(newval, oldval) {
        if (newval) {
          var newData = JSON.parse(sessionStorage.getItem('xinjianType'));
         
          newData.manager = JSON.stringify(newval)||[];

          sessionStorage.setItem('xinjianType', JSON.stringify(newData));
          console.log(newData)
        }
      },
      checkopId(newval, oldval) {
        if (newval) {
          var newData = JSON.parse(sessionStorage.getItem('xinjianType'));
          newData.manager_name = JSON.stringify(newval);
          sessionStorage.setItem('xinjianType', JSON.stringify(newData));
          console.log(newData)
        }
      },
      approvalInstructions(newval, oldval) {
        if (newval) {
          var newData = JSON.parse(sessionStorage.getItem('xinjianType'));
          console.log(JSON.parse(sessionStorage.getItem('xinjianType')))
          newData.flow_desc = newval;
          sessionStorage.setItem('xinjianType', JSON.stringify(newData));
          console.log(sessionStorage.getItem('xinjianType'))
        }
      },
    },
    created() {
      this.formTypeBtn = sessionStorage.getItem('formTypeBtn');
      console.log(sessionStorage.getItem('xinjianType'))
      this.tubiaoType = sessionStorage.getItem('xinjianTypeBtn');
      var xinjianType = JSON.parse(sessionStorage.getItem('xinjianType'));
      console.log(xinjianType);
      setTimeout(() => {
        if (sessionStorage.getItem('xinjianTypeBtn') == 'xinjian') {
          // 新建初始化
          this.approvalName = xinjianType.flow_name;
          this.iconUrl = xinjianType.logo_url;
          this.grouping = xinjianType.flow_group_id;
          this.approvalInstructions = xinjianType.flow_desc;
          this.checkop = xinjianType.manager.length <= 0 ? xinjianType.manager : JSON.parse(xinjianType.manager);
          console.log(this.checkop, xinjianType)
        } else if (sessionStorage.getItem('xinjianTypeBtn') != 'xinjian') {

          console.log(sessionStorage.getItem('xinjianType'), xinjianType, sessionStorage.getItem('formTypeBtn'))
          if (sessionStorage.getItem('formTypeBtn') == "2") {
            // 编辑公共表单初始化
            console.log(xinjianType)
            this.approvalName = xinjianType.flow_name;
            this.grouping = xinjianType.flow_group_id;
            this.approvalInstructions = xinjianType.flow_desc;
            this.checkop = xinjianType.manager == undefined ? [] : JSON.parse(xinjianType.manager);
          }
          if (sessionStorage.getItem('formTypeBtn') == "1") {
            //编辑自定义表单初始化
            this.approvalName = xinjianType.flow_name;
            this.iconUrl = xinjianType.logo_url;
            this.grouping = xinjianType.flow_group_id;
            this.approvalInstructions = xinjianType.flow_desc;
            this.checkop = xinjianType.manager == undefined ? [] : JSON.parse(xinjianType.manager);
          }

        }
        const param = {
          ent_id: xinjianType.ent_id
        }
        query_flow_group_info_list({
          data: param
        }).then(res => {
          if (res.code == 200) {
            console.log(res,'这是分组信息+++——————————');
            const data = res.body.data;
            this.options = data;
          }
        })
      }, 0);
    },
    mounted(){

    },
    methods: {
      changeDialog(val) {
        this.dialogVisible = false;
        this.checkop = val.map(x => x.label);
      },
      iconFun(index) {
        this.iconUrl = this.icons[index].value;
      },
      handleChoose() {
        this.dialogVisible = true;
      },
      outputNameText() {
        this.dialogVisible = false;
      }
    }
  }

</script>
<style lang="scss" scoped>
  .checkopStyle {
    overflow: auto;
    display: inline !important;
  }

  .iconbox {
    height: 300px;
    margin-top: 20px;
    box-sizing: border-box;
    overflow: hidden;
  }

  .iconbox p {
    line-height: 40px;
  }

  .wf-iconselect {
    width: 100%;
    height: 300px;
    overflow-y: auto;
    padding-bottom: 60px;
  }

  .wf-iconselect img {
    width: 58px;
    height: 58px;
    margin: 5px 12px;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #ccc; //3f9af9
    cursor: pointer;
  }

  .appbg {
    background: #f4f7fd;
    min-height: 94vh;
    padding-top: 20px;
    display: flex;
    justify-content: center;
  }

  .appbg>div {
    width: 785px;
    height: 710px;
    padding: 22px 55px;
    background: #fff;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    text-align: left;
  }

  .appbg>div span {
    display: flex;
    align-items: center;
  }

  .el-input {
    width: 656px !important;
  }

  .el-input__inner {
    width: 600px !important;
  }

  .grayBLock {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .grayBLock .choose-box {
    width: 45%;
    height: 354px;
    overflow: auto;
  }

  .grayBLock .sure-box {
    width: 45%;
    height: 354px;
    overflow-y: auto;
    padding-left: 20px;
    background: #e7e8eb;
  }

  .grayBLock .sure-box div {
    padding-top: 20px;
  }

  .el-collapse-item {
    width: 100%;
    height: auto;
  }

  .el-collapse-item__header {
    border-bottom: none;
    padding-left: 20px;
  }

  .el-collapse-item__content {
    background: #e7e8eb;
    border-bottom: none;
    padding-left: 20px;
  }

  .el-checkbox-group {
    display: flex;
    flex-direction: column;
  }

</style>
