<template>
  <div class="biaodan">
    <div class="wf-wrapper" v-on:mouseup="moveend" v-on:mousemove="move">
      <div class="wf-main">
        <mainLeft></mainLeft>
        <formcanvas></formcanvas>
        <setting></setting>
      </div>
      <dragging></dragging>
    </div>
  </div>
</template>

<script>
import mainLeft from './moudle/mainLeft'
import formcanvas from './moudle/formcanvas'
import setting from './moudle/setting'
import dragging from './moudle/dragging'
import { query_cust_form_detail } from '@api/shenpi.js'
export default {
  name: 'App',
  inject: ['info', 'fooMethod'],
  components: {
    mainLeft,
    formcanvas,
    setting,
    dragging
  },
  data: function() {
    return {
      isstart: false,
      componentView: {}
    }
  },
  methods: {
    move: function(e) {
      if (this.isstart) {
        document.querySelector('html').classList.add('wf-cursor-move')
        const obj = {
          componentName: this.componentView.componentName,
          clientX: e.clientX,
          clientY: e.clientY
        }
        drag.$emit('moveInCanvas', obj)
        drag.$emit('move', e)
      }
    },
    moveend: function(e) {
      if (this.isstart) {
        const obj = {
          componentView: this.componentView
        }
        drag.$emit('moveend', obj)
        this.isstart = false
      }
    }
  },
  created: function() {
    

    const self = this
    drag.$on('movestart', function(obj) {
      self.isstart = true
      self.componentView = obj.componentView
    })
  }
}
</script>
<style scoped lang="scss">
@import './moudle/design.css';
.biaodan {
  width: 100%;
  height: 94vh;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
* {
  -webkit-user-select: none;
  -moz-user-select: none;
}
</style>
