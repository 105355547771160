
<template>
  <div class="appbg">
    <div>
      <div>
        <span>
          <span style="color: red;">*</span>审批人去重
        </span>
        <div style="padding-top: 20px;">
          <el-select v-model="approvalName" placeholder="请选择审批人去重">
            <el-option v-for="(item, index) in option" :label="item.label" :value="item.value" :key="index"></el-option>
          </el-select>
          <!-- <span class="bluee">自动去重规则说明</span> -->
        </div>
      </div>
      <div style="padding-top: 20px;">
        <el-checkbox v-model="checked">发起人审批时自动通过</el-checkbox>
      </div>
      <div style="padding-top: 20px;">
        <span>
          <span style="color: red;"></span>审批意见提示
          <span style="color: #cccccc;padding-left: 10px;">最多50字</span>
        </span>
        <div style="padding-top: 20px;">
          <el-input v-model="approvalInstructions" placeholder="请输入审批说明" size="medium" maxlength="50"></el-input>
        </div>
      </div>
      <div style="padding-top: 20px;">
        <el-checkbox v-model="opp">审批意见是否必填</el-checkbox>
      </div>
      <!-- <div style="padding-top: 20px;">
        <span>
          <el-checkbox v-model="replaceBtn">允许代他人提交</el-checkbox>
          <span style="color: #cccccc;padding-left: 10px;">默认所有人可以使用代提交</span>
        </span>
        <div v-show="replaceBtn" style="padding-top: 20px;">
          <el-button
          style="width: 675px;height: 40px;border: 1px solid #DCDFE6;color: #DCDFE6;text-align: left;text-indent: 1em;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;"
          type="text"
          @click="dialogVisible = true"
          v-model="replaceText"
          size="medium"
          maxlength="50">
            <span class="checkopStyle" :style="{'color': checkop.length ? '#000' : '#dcdfe6' }"
            v-show="checkop.length>0" v-for="(i,index) in checkop" :key="index">{{i+'、'}}</span>
            <span v-show="checkop.length==0">点击设置代提交人，可以设置主管和指定员工</span>
           </el-button>
        </div>
      </div> -->
      <!-- 管理模板导出弹框 -->
       <c-tree
        :dialogVisible.sync="dialogVisible"
        @changeDialog="changeDialog"
        :drtjData="drtjData"
      ></c-tree>
    </div>
  </div>
</template>
<script>
import { get_my_info, query_form_replace_write } from '../../api/public.js';
import { query_dept_staff_tree_aim_not } from '../../api/user.js';
import { query_flow_group_info_list,query_flow_info_all,query_cust_form_detail  } from '@api/shenpi.js';
 const xinjianType = JSON.parse(sessionStorage.getItem('xinjianType'));
export default {
  data() {
    return {
      drtjData: [],
      activeNames: null,
      checkop: [],
      checkopId: [],
      dialogVisible: false,
      itemList: [],
      rw_value_type: '1',
      // 1同一个审批人在流程中出现多次时保留一个 2同一个审批人仅连续出现时自动去重 3不启用自动去重
      approvalName: '3',
      grouping: null,
      checked: false,//appr_auto
      opp: false,
      replaceBtn: false,
      approvalInstructions: null,
      replaceText: null,
      approvalManagement: null,
      option: [
        {
          label: '同一个审批人在流程中出现多次时，仅保留一个',
          value: '1'
        },
        {
          label: '同一个审批人仅在连续出现时，自动去重',
          value: '2'
        },
        {
          label: '不启用自动去重',
          value: '3'
        }
      ]
    }
  },
  watch: {
    checkop(newval,oldval){
      let newData = JSON.parse(sessionStorage.getItem('xinjianType'));
      console.log(newData.rw_value,'kxb11111111')
      newData.rw_value = newval;
      newData.rw_table = newval
      sessionStorage.setItem('xinjianType',JSON.stringify(newData));

    },
    checkopId(newval,oldval){
      let newData = JSON.parse(sessionStorage.getItem('xinjianType'));
      newData.rw_table = newval
      sessionStorage.setItem('xinjianType',JSON.stringify(newData));
    },
    checked(newval,oldval){
      let newData = JSON.parse(sessionStorage.getItem('xinjianType'));
      newData.appr_auto = newval ? "1" : "0";
      sessionStorage.setItem('xinjianType',JSON.stringify(newData));
    },
    approvalInstructions(newval,oldval){
      let newData = JSON.parse(sessionStorage.getItem('xinjianType'));
      newData.appr_tips = newval;
      sessionStorage.setItem('xinjianType',JSON.stringify(newData));
    },
    opp(newval,oldval){
      let newData = JSON.parse(sessionStorage.getItem('xinjianType'));
      newData.appr_must_flag = newval ? "1" : "0";
      sessionStorage.setItem('xinjianType',JSON.stringify(newData));
    },
    approvalName(newval,oldval){
      let newData = JSON.parse(sessionStorage.getItem('xinjianType'));
      newData.distinct_flag = newval;
      sessionStorage.setItem('xinjianType',JSON.stringify(newData));
    },
    replaceBtn(newval,oldval){
      let newData = JSON.parse(sessionStorage.getItem('xinjianType'));
      newData.flag_rw = newval ? "1" : "0";
      sessionStorage.setItem('xinjianType',JSON.stringify(newData));
    },
  },
  created() {
    let xinjianType = JSON.parse(sessionStorage.getItem('xinjianType'));
    xinjianType.flag_rw='0'
    console.log(xinjianType)
    let self=this
    setTimeout(() => {
      if(sessionStorage.getItem('xinjianTypeBtn') == 'xinjian'){
        // 新建初始化
        self.approvalInstructions = xinjianType.appr_tips;
        self.opp = xinjianType.appr_must_flag == "1" ? true : false;
        self.approvalName = '3';
        self.checked = xinjianType.appr_auto == "1" ? true : false;
        self.replaceBtn = xinjianType.flag_rw == "1" ? true : false;
        xinjianType.rw_table == null ? self.checkopId = [] : self.checkopId = xinjianType.rel_table;
        xinjianType.rw_value == null ? self.checkop = [] : self.checkop = xinjianType.rw_value;
        console.log(self.checkop,sessionStorage.getItem('xinjianType'))

      }else if(sessionStorage.getItem('xinjianTypeBtn') != 'xinjian'){
        if(sessionStorage.getItem('formTypeBtn') == "2"){
          // 编辑公共表单初始化
            self.approvalInstructions = xinjianType.appr_tips;
            self.opp = xinjianType.appr_must_flag == "1" ? true : false;
            self.approvalName = xinjianType.distinct_flag;
            self.replaceBtn = xinjianType.flag_rw == "1" ? true : false;
            self.checked = xinjianType.appr_auto == "1" ? true : false;
            self.checkop = JSON.parse(xinjianType.rw_value) || [];
            self.checkopId = xinjianType.rw_table;
        }else{
          //编辑自定义表单初始化
          query_cust_form_detail({
            data: {ent_id: xinjianType.ent_id,flow_id: xinjianType.flow_id}
          }).then(res=>{
            if(res.code == 200){
              var data = res.body.data;
              console.log(res.body)
              self.approvalInstructions = data.appr_tips;
              self.opp = data.appr_must_flag == "1" ? true : false;
              self.approvalName = data.distinct_flag;
              self.replaceBtn = data.flag_rw == "1" ? true : false;
              self.checked = data.appr_auto == "1" ? true : false;
              data.rw_value == null ? self.checkop = [] : self.checkop = JSON.parse(data.rw_value);
              data.rel_table == null ? self.checkopId = [] : self.checkopId = JSON.parse(data.rel_table);
            }
          })
        }
      }
    }, 120);
  },
  methods: {
    changeDialog(val) {
      this.dialogVisible = false;
      this.checkop = val.map(x => x.label);
      this.checkopId = val.map(x => x.id);
    },
    outputNameText() {

    }
  }
}
</script>
<style lang="scss" scoped>
.checkopStyle{
  overflow: auto;

}
  .appbg{
    background: #f4f7fd;
    min-height: 100vh;
    padding-top: 20px;
    display: flex;
    justify-content: center;
    &>div{
      width: 785px;
      height: 693px;
      padding: 22px 55px;
      background: #fff;
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      text-align: left;
      &>span{
        display: flex;
        align-items: center;
      }
    }
  }
  .el-input{
    width: 656px !important;
  }
  ::v-deep .el-input__inner{
    width: 656px !important;
  }
  .bluee{
    padding-left: 10px;
    color: #1b70ff;
  }

  .grayBLock{
    display: flex;
    justify-content: space-around;
    align-items: center;
    .choose-box{
      width: 45%;
      height: 354px;
      background: #e7e8eb;
      overflow: auto;
    }
    .sure-box{
      width: 45%;
      height: 354px;
      overflow-y: auto;
      padding-left: 20px;
      background: #e7e8eb;
      div{
        padding-top: 20px;
      }
    }
  }
  ::v-deep .el-collapse-item{
    width: 100%;
    background: #e7e8eb;
    height: auto;
  }
  ::v-deep .el-collapse-item__header{
    background: #e7e8eb;
    border-bottom: none;
    padding-left: 20px;
  }
  ::v-deep .el-collapse-item__content{
    background: #e7e8eb;
    border-bottom: none;
    padding-left: 20px;
  }
  ::v-deep .el-checkbox-group{
    display: flex;
    flex-direction: column;
  }
</style>
