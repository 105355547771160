<template>
  <div class="Approval">
    <div class="top">
      <li>
        <p v-for="(item, index) in title" :key="index" @click="components = item.components">
          <span :class="{ active: item.components === components }">{{
            item.id
          }}</span>{{ item.name }}
        </p>
      </li>
      <li>
        <p style="box-sizing: border-box;border: 1px solid #fff;height: 90%;margin-top: 2px;margin-right: 5px;cursor: pointer;"
          @click="deketeSess(); $router.back(-1)">取消</p>
        <p style="box-sizing: border-box;border: 1px solid #fff;height: 90%;margin-top: 2px;margin-right: 5px;cursor: pointer;"
          @click="save()">
          <i>保存</i>
        </p>
      </li>
    </div>
    <div class="main">
      <component :is="components" ref="shnenpizujian"></component>
    </div>
  </div>
</template>

<script>
  import {
    insert_cust_form,
    query_cust_form_detail,
    insert_flow_info,
    insert_flow_action_str,
    replace_flow_form_info_private
  } from '@api/shenpi';
   import advancedSetting from './advancedSetting';
   import basicSettings from './basicSettings';
   import liuchengSetting from './liuchengSetting';
   import fromSetting from './fromSetting';
  const info = JSON.parse(sessionStorage.getItem('pc_mlbb_jichuxinxi'));
  export default {
    watch: {
      $route(to, from) {
        console.log(from.name)
        if (from.name !== 'SettingsApproval') {
          this.info = info.mobile === '18222856355'
        } else {
          this.info = false
        }
      }
    },
    computed: {
      
      ent_id() {
        return this.$store.state.ent_id
      },
      user_id() {
        return this.$store.state.user_id
      }
    },
    data() {
      return {
        xinjianTypeBtn: '',
        components: 'basicSettings',
        info: false,
        data: {},
        title: [{
            id: '1',
            name: '基础设计',
            components: 'basicSettings'
          },
          {
            id: '2',
            name: '表单设计',
            components: 'fromSetting'
          },
          {
            id: '3',
            name: '流程设计',
            components: 'liuchengSetting'
          },
          {
            id: '4',
            name: '高级设计',
            components: 'advancedSetting'
          }
        ],
        las: '',
        // ent_id:''
      }
    },
    provide() {
      return {
        info: this.info,
        fooMethod: (x) => {
          this.data.name = x
        }
      }
    },
    created() {
      this.xinjianType = sessionStorage.getItem('xinjianType')
      // this.$store.state.ent_id = this.$store.state.ent_id;
      // this.$store.state.ent_id=JSON.parse(sessionStorage.getItem('qiye')).staff_info.ent_id
      // console.log(sessionStorage.getItem('qiye').staff_info.ent_id)
      
      this.xinjianTypeBtn = sessionStorage.getItem('xinjianTypeBtn')
    },
    components: {
       advancedSetting,
      basicSettings,
      liuchengSetting,
      fromSetting
    },
    methods: {
      deketeSess() {
        sessionStorage.removeItem('xinjianType');
        sessionStorage.removeItem('mlbb_one_shezhi');
        sessionStorage.removeItem('mlbb_four_shezhi');
        sessionStorage.removeItem('mlbb_biaodan_sheji');
        sessionStorage.removeItem('mlbb_shenpi_zhengyi');
        sessionStorage.removeItem('mlbb_liucheng_shezhi');
        sessionStorage.removeItem('mlbb_liucheng_shezhi_list');
        sessionStorage.removeItem('mlbb_liucheng_shezhi_tableId');
        sessionStorage.removeItem('mlbb_liucheng_shezhi_flowPermission');
      },
      addData() {
        this.$message('操作成功')
      },
      getThisNodeConfig(NOde) {
        if (NOde.nodeConfig.nodeConfig) {
          this.getThisNodeConfig(NOde.nodeConfig)
        } else {
          this.las = NOde
        }
      },
      // 点击保存
      save() {
        
        var xinjianData = JSON.parse(sessionStorage.getItem('xinjianType'));

        const liucheng_list = {
          nodeConfig: [],
          flowPermission: null,
          tableId: null,
          all_or_not: 0,
          faqi_fanwei_name: '所有人'
        }
        liucheng_list.flowPermission = this.$bendi_naqu_cunchu('mlbb_liucheng_shezhi_flowPermission', '暂时')
        if (sessionStorage.getItem('mlbb_liucheng_shezhi') != undefined) {
          liucheng_list.nodeConfig = JSON.parse(sessionStorage.getItem('mlbb_liucheng_shezhi'))

        }
        liucheng_list.flowPermission = this.$bendi_naqu_cunchu('mlbb_liucheng_shezhi_flowPermission', '暂时')
        if (liucheng_list.flowPermission != undefined && liucheng_list.flowPermission.length != 0) {
          liucheng_list.all_or_not = 1
          liucheng_list.faqi_fanwei_name = liucheng_list.flowPermission.map(ite => ite.name).join(',')
        }
        if (this.$bendi_naqu_cunchu('mlbb_liucheng_shezhi_tableId', '暂时') != undefined) {
          liucheng_list.tableId = this.$bendi_naqu_cunchu('mlbb_liucheng_shezhi_tableId', '暂时')
        }
      
        this.getThisNodeConfig(liucheng_list)


        if(!xinjianData.distinct_flag){
          xinjianData.distinct_flag='3'
        }
        if (xinjianData.flow_name && xinjianData.flow_group_id && xinjianData.distinct_flag) {
          if (this.xinjianTypeBtn != 'xinjian') {
            // 点击编辑
            console.log(sessionStorage.getItem('xinjianType'), this.xinjianTypeBtn, 111)
            if (sessionStorage.getItem('formTypeBtn') == "1") {
              // 自定义
              const param = {

                ent_id: this.$store.state.ent_id,
                user_id: this.user_id,
                flow_id: xinjianData.flow_id,
                flow_name: xinjianData.flow_name,
                flow_desc: xinjianData.flow_desc,
                flow_group_id: xinjianData.flow_group_id,
                manager: xinjianData.manager,
                logo_url: xinjianData.logo_url,
                rel_table: xinjianData.rel_table,
                appr_must_flag: xinjianData.appr_must_flag,
                appr_tips: xinjianData.appr_tips,
                appr_auto: xinjianData.appr_auto,
                distinct_flag: xinjianData.distinct_flag,
                flag_rw: xinjianData.flag_rw,
                rw_value: xinjianData.rw_table ? xinjianData.rw_table.join(',') : '',
                rw_value_type: '1',
                form_set: sessionStorage.getItem('mlbb_biaodan_sheji'),
                flow_set: JSON.stringify(this.las),
                print_flag: xinjianData.print_flag,

              }
              console.log(param)
              insert_cust_form({
                data: param
              }).then(res => {
                console.log(xinjianData, res)
                if (res.code == 200) {
                  console.log(res)
                  this.$message({
                    message: '保存成功',
                    type: 'success'
                  })
                  //  window.location.href="/SettingsEnterprise/SettingsApproval";
                  this.$router.back()
                  this.deketeSess()
                } else {
                  console.log(res)
                  this.$message({
                    message: '保存失败',
                    type: 'warning'
                  })
                }
              })
            } else if (sessionStorage.getItem('formTypeBtn') == "2") {
              //编辑公共表单
              var gongshi_baocuo = false;
              const bitian_zhi = [];
              console.log(this.$bendi_naqu_cunchu('mlbb_biaodan_sheji', '暂时'))
              

              var cunchu_data = this.$bendi_naqu_cunchu('mlbb_biaodan_sheji', '暂时') != undefined ?  this.$bendi_naqu_cunchu('mlbb_biaodan_sheji', '暂时') : [];
              
              console.log(this.$bendi_naqu_cunchu('mlbb_biaodan_sheji', '暂时'))
              for (let sort = 0; sort < cunchu_data.length; sort++) {
                console.log(sort)
                cunchu_data[sort].sort = sort + '';
                console.log(cunchu_data)
                
                if (cunchu_data[sort].components != undefined) {
                  var components = cunchu_data[sort].components;
                  if(components[0] == '['){
                    components = JSON.parse(components)
                  }
                  for (let nei_sort = 0; nei_sort < components.length; nei_sort++) {
                    components[nei_sort].sort = nei_sort;
                  }
                }
                if (cunchu_data[sort].componentName == 'calcform' && cunchu_data[sort].gongshi.length == 0) {
                  gongshi_baocuo = true;
                }
                if (cunchu_data[sort].defaultImportant) {
                  bitian_zhi.push('1');
                }
              };
              if (cunchu_data.length && !bitian_zhi.length) {
                console.log(cunchu_data, bitian_zhi)
                this.moren = 1;
                this.$message({
                  message: '表单必须有一个控件为必填值',
                  type: 'warning'
                })
              } else {
                var that = this;
                if (!gongshi_baocuo) {
                  this.getThisNodeConfig(liucheng_list)
                  
                  insert_flow_info({
                    data: {
                      ent_id: this.$store.state.ent_id,
                      flow_id: xinjianData.flow_id,
                      flow_name: xinjianData.flow_name,
                      flow_desc: xinjianData.flow_desc,
                      flow_group_id: xinjianData.flow_group_id,
                      manager: xinjianData.manager,
                      rel_table: xinjianData.rel_table,
                      appr_must_flag: xinjianData.appr_must_flag,
                      appr_tips: xinjianData.appr_tips,
                      appr_auto: xinjianData.appr_auto,
                      distinct_flag: xinjianData.distinct_flag,
                      flag_rw: xinjianData.flag_rw,
                      rw_value: xinjianData.rw_table ? xinjianData.rw_table.join(',') : '',
                      rw_value_type: xinjianData.rw_value_type
                    }
                  }).then(res => {
                    console.log(res)
                  if (res.code =='1200'||res.code =='1100') {
                    this.$message({
                      message: '您所提交的内容可能存在敏感信息，请编辑后重新提交',
                      type: 'error'
                    })
                  }
                  })
                  this.getThisNodeConfig(liucheng_list)
                  insert_flow_action_str({
                    data: {
                      ent_id:this.$store.state.ent_id,
                      flow_id: xinjianData.flow_id,
                      action_str: JSON.stringify(that.las),
                    }
                  }).then(res => {
                    console.log(xinjianData, res)
                    if (res.code == 200) {
                      console.log(res)
                      
                      var formData = JSON.parse(sessionStorage.getItem('mlbb_biaodan_sheji'));
                      console.log(cunchu_data)
                      // cunchu_data.forEach((item,index) => {
                      //   if(item.componentName == 'tablefield' && typeof(item.components) == 'string'){
                      //     cunchu_data[index].components = JSON.parse(item.components)
                      //   }else{
                      //     cunchu_data[index].components = JSON.stringify(item.components)
                      //   }
                      // });
                      replace_flow_form_info_private({
                        data: {
                          ent_id: this.$store.state.ent_id,

                          flow_id: xinjianData.flow_id,

                          list: JSON.stringify(cunchu_data)

                        }
                      }).then(res => {
                        console.log(xinjianData, res)
                        if (res.code == 200) {
                          console.log(res)
                          this.$message({
                            message: '保存成功',
                            type: 'success'
                          })
                           this.$router.back()
                          //window.location.href = "/SettingsEnterprise/SettingsApproval";
                          this.deketeSess()
                        }
                      })
                    }
                  })

                }
              }

            }
          } else if (this.xinjianTypeBtn == 'xinjian') {
                
                if(xinjianData.rw_table instanceof Array&&xinjianData.rw_table){
                    xinjianData.rw_table=xinjianData.rw_table.join(',')
                }else{

                }

this.getThisNodeConfig(liucheng_list)
            //自定义新建
            const param =
              {
                ent_id: this.$store.state.ent_id,
                user_id: this.user_id,
                flow_id: xinjianData.flow_id,
                flow_name: xinjianData.flow_name,
                flow_desc: xinjianData.flow_desc,
                flow_group_id: xinjianData.flow_group_id,
                manager: xinjianData.manager,
                logo_url: xinjianData.logo_url,
                rel_table: xinjianData.rel_table,
                appr_must_flag: xinjianData.appr_must_flag,
                appr_tips: xinjianData.appr_tips,
                appr_auto: xinjianData.appr_auto,
                form_set: sessionStorage.getItem('mlbb_biaodan_sheji'),
                flow_set: JSON.stringify(this.las),
                distinct_flag: xinjianData.distinct_flag,
                print_flag: xinjianData.print_flag,
                rw_value: xinjianData.rw_table,
              }
            console.log(param,'1313131------');
           
            insert_cust_form({
              data: param
            }).then(res => {
              console.log(res, '新建表单', xinjianData)
              if (res.code == 200) {
                var data = res.body;
                console.log(data);
                this.$message({
                  message: '保存成功',
                  type: 'success'
                })
                this.$router.back()
              //  window.location.href = "/SettingsEnterprise/SettingsApproval";
                this.deketeSess()
              } else {
                this.$message({
                  message: '保存失败',
                  type: 'warning'
                })
              }
            })
          }
        } else {
          this.$message({
            message: '必填项不能为空',
            type: 'warning'
          })
        }
      }
    },
    mounted() {
      // console.log(this.$children)
    }
  }
</script>
<style lang="scss" scoped>
  .top {
    width: 100%;
    height: 50px;
    background: linear-gradient(200deg, #fc4353, #ff5e41);
    color: #fff;
    display: flex;
    font-size: 16px;

    >li {
      display: flex;
      line-height: 50px;
      justify-content: center;

      >p {
        width: 200px;
        line-height: 50px;
        text-align: center;

        >span {
          width: 20px;
          height: 20px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          background: linear-gradient(200deg, #fc4353, #ff5e41);
          border-radius: 20px;
          line-height: 50px;
          margin-right: 10px;
          border: 1px solid #fff;
          &.active {
            background-color: #fff;
            color: linear-gradient(200deg, #fc4353, #ff5e41);
          }
        }
      }

      &:first-child {
        flex: 1;
      }
      &:last-child {
        width: 200px;
        >p:nth-child(2) {
          background: #fff;
          color: #fc4353;
          border: 2px solid linear-gradient(200deg, #fc4353, #ff5e41);
        }
      }
    }
  }

</style>
